









































import _ from "lodash"
import { Component, Prop, Watch } from "vue-property-decorator"
import Vue from "vue"
import ConcessionCard from "@/components/ConcessionCard.vue"
import StandardConcessionCard from "@/components/StandardConcessionCard.vue"

@Component({
  components: { ConcessionCard, StandardConcessionCard },
})
export default class ConcessionsList extends Vue {
  @Prop() zone!: any
  @Prop() totalPurchasableSeatsCount!: number
  @Prop({ default: false }) hasMoreZones!: boolean
  @Prop({ default: false }) hasFees!: boolean

  sessionzoneLine: {
    numTickets: number
    totalPrice: number
    concessionId: number | null
  } | null = null
  concessionLines: Array<{
    numTickets: number
    totalPrice: number
    concessionId: number
  }> = []

  get numTickets() {
    return this.$store.state.shoppingCart.cart.length
  }

  goToSession() {
    this.$router.push({ name: "session", query: this.$defaultQuery() })
  }

  confirm() {
    const lines: Array<{
      sessionZoneId: number
      seatId: number | null
      concessionId: number | null
    }> = []
    if (this.sessionzoneLine && this.sessionzoneLine.numTickets > 0) {
      _.each(new Array(this.sessionzoneLine.numTickets), () => {
        lines.push({ sessionZoneId: this.zone.id, seatId: null, concessionId: null })
      })
    }

    _.each(this.concessionLines, (line) => {
      if (!line || line.numTickets < 1) return
      _.each(new Array(line.numTickets), () => {
        lines.push({
          sessionZoneId: this.zone.id,
          seatId: null,
          concessionId: line.concessionId,
        })
      })
    })

    if (this.totalPurchasableSeatsCount < this.numTickets + lines.length) {
      const translated = this.$gettext(
        "El máximo de localidades que se pueden comprar por pedido es de %{ n }"
      )
      const msg = this.$gettextInterpolate(translated, {
        n: this.totalPurchasableSeatsCount,
      })
      this.$store.dispatch("ui/error", msg)
      return
    }

    this.$store.dispatch("shoppingCart/update", ["addLines", lines])

    if (lines.length > 0) {
      this.$emit("seatAdded", lines)
    }
  }

  @Watch("$store.state.shoppingCart.cart.length")
  onCartLengthChange() {
    this.sessionzoneLine = null
    this.concessionLines = []
  }
}
