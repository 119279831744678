




























































































import _ from "lodash"
import { Component, Prop, Watch } from "vue-property-decorator"
import Vue from "vue"
import svgJs from "svg.js"
import Hammer from "hammerjs"
import ConfirmSeatDialog from "@/components/ConfirmSeatDialog.vue"

const SEAT_RADIUS = 20
const SEAT_LABEL_PADDING = 15
const LABEL_OFFSETS = {
  top: { x: 5, y: -SEAT_RADIUS / 1.5 },
  right: { x: (SEAT_RADIUS * 3) / 2.5, y: 5 },
  bottom: { x: 5, y: (SEAT_RADIUS * 3) / 2.5 },
  left: { x: -SEAT_RADIUS / 4, y: 5 },
}

const seatGraph = [
  {
    type: "rect",
    attr: {
      class: "seat-fill",
      height: "366.86431",
      width: "286.24168",
      y: "23.65427",
      x: "81.19165",
    },
  },
  {
    type: "rect",
    attr: {
      class: "seat-fill",
      height: "144.99578",
      width: "316.2408",
      y: "41.77874",
      x: "66.81707",
    },
  },
  {
    type: "rect",
    attr: {
      class: "seat-fill",
      height: "204.99403",
      width: "375.61407",
      y: "184.89957",
      x: "36.81795",
    },
  },
  {
    type: "ellipse",
    attr: {
      class: "seat-fill",
      ry: "44.06122",
      rx: "40.31133",
      cy: "218.3361",
      cx: "49.00509",
    },
  },
  {
    type: "ellipse",
    attr: {
      class: "seat-fill",
      ry: "44.06122",
      rx: "40.31133",
      cy: "220.21104",
      cx: "398.9949",
    },
  },
  {
    type: "ellipse",
    attr: {
      fill: "#FFF",
      ry: "16.24953",
      rx: "18.43696",
      cy: "411.80703",
      cx: "87.75397",
    },
  },
  {
    type: "ellipse",
    attr: {
      fill: "#FFF",
      ry: "16.24953",
      rx: "18.43696",
      cy: "410.86956",
      cx: "359.30856",
    },
  },
  {
    type: "path",
    attr: {
      d:
        "m280,307.999l-15.997,0c-4.418,0 -8,3.582 -8,8s3.582,8 8,8l15.997,0c4.418,0 8,-3.582 8,-8s-3.582,-8 -8,-8z",
    },
  },
  {
    type: "path",
    attr: {
      d:
        "m396,164c-1.347,0 -2.678,0.068 -4,0.169l0,-96.169c0,-30.879 -25.122,-56 -56,-56l-224,0c-30.878,0 -56,25.121 -56,56l0,96.169c-1.322,-0.101 -2.653,-0.169 -4,-0.169c-28.673,0 -52,23.327 -52,52c0,17.936 9.079,34.304 24,43.815l0,105.518c0,0.358 0.032,0.709 0.078,1.055c1.24,20.947 18.668,37.612 39.922,37.612l0,8c0,13.233 10.766,24 24,24s24,-10.767 24,-24l0,-8l224,0l0,8c0,13.233 10.766,24 24,24s24,-10.767 24,-24l0,-8c21.254,0 38.682,-16.665 39.922,-37.612c0.046,-0.346 0.078,-0.697 0.078,-1.055l0,-105.518c14.921,-9.512 24,-25.88 24,-43.815c0,-28.673 -23.327,-52 -52,-52zm-324,-96c0,-22.056 17.944,-40 40,-40l224,0c22.056,0 40,17.944 40,40l0,100.005c-18.773,7.853 -32,26.406 -32,47.995l0,36.022c-6.69,-5.034 -15.002,-8.022 -24,-8.022l-192,0c-8.998,0 -17.31,2.988 -24,8.022l0,-36.022c0,-21.589 -13.227,-40.142 -32,-47.995l0,-100.005zm24,344c0,4.411 -3.589,8 -8,8s-8,-3.589 -8,-8l0,-8l16,0l0,8zm272,0c0,4.411 -3.589,8 -8,8s-8,-3.589 -8,-8l0,-8l16,0l0,8zm44.358,-163.936c-2.675,1.368 -4.358,4.119 -4.358,7.123l0,108.813c0,13.233 -10.766,24 -24,24l-320,0c-13.234,0 -24,-10.767 -24,-24l0,-108.812c0,-3.004 -1.683,-5.755 -4.358,-7.123c-12.116,-6.196 -19.642,-18.482 -19.642,-32.065c0,-19.851 16.149,-36 36,-36s36,16.149 36,36l0,68.001l0,0.001c0.001,22.055 17.944,39.997 40,39.997l103.998,0c4.418,0 8,-3.582 8,-8s-3.582,-8 -8,-8l-103.998,0c-13.233,0 -24,-10.766 -24,-23.999s10.767,-24 24,-24l192,0c13.233,0 24,10.767 24,24s-10.767,23.999 -24,23.999l-8,0c-4.418,0 -8,3.582 -8,8s3.582,8 8,8l8,0c22.055,0 39.999,-17.942 40,-39.997l0,-0.001l0,-68.001c0,-19.851 16.149,-36 36,-36s36,16.149 36,36c0,13.583 -7.526,25.869 -19.642,32.064z",
    },
  },
]

const wheelChair = {
  type: "path",
  attr: {
    class: "wheel-chair",
    transform: "matrix(0.025, 0, 0, 0.025, 3.9, 3.5)",
    x: "0.5",
    y: "300",
    d:
      "M496.101 385.669l14.227 28.663c3.929 7.915.697 17.516-7.218 21.445l-65.465 32.886c-16.049 7.967-35.556 1.194-43.189-15.055L331.679 320H192c-15.925 0-29.426-11.71-31.679-27.475C126.433 55.308 128.38 70.044 128 64c0-36.358 30.318-65.635 67.052-63.929 33.271 1.545 60.048 28.905 60.925 62.201.868 32.933-23.152 60.423-54.608 65.039l4.67 32.69H336c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H215.182l4.572 32H352a32 32 0 0 1 28.962 18.392L438.477 396.8l36.178-18.349c7.915-3.929 17.517-.697 21.446 7.218zM311.358 352h-24.506c-7.788 54.204-54.528 96-110.852 96-61.757 0-112-50.243-112-112 0-41.505 22.694-77.809 56.324-97.156-3.712-25.965-6.844-47.86-9.488-66.333C45.956 198.464 0 261.963 0 336c0 97.047 78.953 176 176 176 71.87 0 133.806-43.308 161.11-105.192L311.358 352z",
  },
}

const iconTick = {
  type: "path",
  attr: {
    class: "icon",
    transform: "matrix(0.02, 0, 0, 0.02, 5, 6)",
    x: "0.5",
    y: "300",
    d:
      "M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z",
  },
}

interface Seat {
  id: number
  coord_x: number
  coord_y: number
  status: string
  representation: string
  row_label_position?: "top" | "right" | "left" | "bottom"
  row: number
  name: string
  has_notice: boolean
  notice_header?: string
  notice_description?: string
}

@Component({
  components: { ConfirmSeatDialog },
})
export default class ZoneMap extends Vue {
  @Prop() totalPurchasableSeatsCount!: number
  @Prop() zone!: {
    id: number
    name: string
    rows_name: string
    zone_seats_icon: "standard" | "circle"
    seats: Seat[]
    layout: Array<Dictionary<any>>
  }
  @Prop({ default: false }) hasMoreZones!: boolean

  initializedMap: boolean = false

  seatLengthX: number = 20
  seatLengthY: number = 20

  svgContainer!: svgjs.Container
  patternAvailable: any = null
  patternNotAvailable: any = null
  patternPerimeterBlock: any = null
  patternSelected: any = null
  patternReducedMobility: any = null

  drawingId: string = `drawing-${this.zone.id}`

  transform: string = ""
  lastPosX: number = 0
  lastPosY: number = 0
  scale: number = 1

  // SeatNoticeModal
  seatMessageGroup: svgjs.G | null = null
  seatWithNotice: Seat | null = null

  closeSeatNoticeDialog() {
    this.seatWithNotice = null
    this.seatMessageGroup = null
  }

  selectConfirmSeatDialog() {
    if (!this.seatMessageGroup) {
      this.closeSeatNoticeDialog()
      return
    }
    this.selectGroup(this.seatMessageGroup)
    this.closeSeatNoticeDialog()
  }

  get numTickets() {
    return this.$store.getters["shoppingCart/numTickets"]
  }

  get expanded() {
    return this.scale > 1
  }

  goToSession() {
    this.$router.push({ name: "session", query: this.$defaultQuery() })
  }

  getViewboxWidth() {
    const seatGroup: svgJs.Element | undefined = _.maxBy(
      this.svgContainer.children(),
      (n: svgJs.Element) => n.x()
    )
    return !seatGroup ? 0 : seatGroup.x() + this.seatLengthX + SEAT_LABEL_PADDING
  }
  getViewboxHeight() {
    const seatGroup: svgJs.Element | undefined = _.maxBy(
      this.svgContainer.children(),
      (n: svgJs.Element) => n.y()
    )
    return !seatGroup ? 0 : seatGroup.y() + this.seatLengthY + SEAT_LABEL_PADDING
  }

  async generateVenueMap() {
    this.svgContainer = svgJs(this.drawingId)
    const pattern = this.svgContainer
      .pattern(455, 455)
      .viewbox(0, 0, 455, 455)
      .attr({ transform: "scale(0.044)" })

    _.each(seatGraph, (item) => {
      if (item.type == "rect") {
        pattern.rect().attr(item.attr)
      } else if (item.type == "path" && item.attr?.d) {
        pattern.path(item.attr.d).attr(item.attr)
      }
      if (item.type == "ellipse") {
        pattern.ellipse().attr(item.attr)
      }
    })
    this.patternAvailable = pattern.clone().attr({ class: "available" })
    this.patternSelected = pattern.attr({ class: "selected" })
    this.patternNotAvailable = pattern.clone().attr({ class: "not-available" })
    this.patternPerimeterBlock = pattern.clone().attr({ class: "perimeter-block" })
    this.patternReducedMobility = pattern.clone().attr({ class: "reduced-mobility" })

    this.initializedMap = true
  }

  expand() {
    const el = document.getElementById(this.drawingId)
    if (el) {
      this.scale = 1.000001
      let pos = this.getPosition(0, 0, el)
      el.style.webkitTransform = `translate(${pos.x}px, ${pos.y}px) scale(${this.scale}, ${this.scale})`
      this.lastPosX = pos.x
      this.lastPosY = pos.y
    }
  }

  compress() {
    const el = document.getElementById(this.drawingId)
    if (el) {
      this.scale = 1
      el.style.webkitTransform = `translate(0px, 0px) scale(${this.scale}, ${this.scale})`
      this.lastPosX = 0
      this.lastPosY = 0
    }
  }

  mounted() {
    this.initListeners()
    this.drawSeats()
  }

  getPosition(deltaX: number, deltaY: number, el: any) {
    let posX = this.lastPosX + deltaX
    let posY = this.lastPosY + deltaY
    let maxPosX = Math.ceil(((this.scale - 1) * el.clientWidth) / 2)
    let maxPosY = Math.ceil(((this.scale - 1) * el.clientHeight) / 2)
    if (posX > maxPosX) {
      posX = maxPosX
    }
    if (posX < -maxPosX) {
      posX = -maxPosX
    }
    if (posY > maxPosY) {
      posY = maxPosY
    }
    if (posY < -maxPosY) {
      posY = -maxPosY
    }

    return { x: posX, y: posY, maxX: maxPosX, maxY: maxPosY }
  }

  initListeners() {
    const myElement = document.getElementById(this.drawingId)
    if (!myElement) return

    myElement.addEventListener("wheel", (e) => {
      e.preventDefault()
      if (this.scale == 1) {
        return
      }
      const delta = 53 / e.deltaY / 4
      this.scale -= delta

      if (this.scale <= 1) {
        this.compress()
        return
      }

      const pos = this.getPosition(delta, delta, myElement)

      myElement.style.webkitTransform = `translate(${pos.x}px, ${pos.y}px) scale(${this.scale}, ${this.scale})`
      this.lastPosX = pos.x
      this.lastPosY = pos.y
    })

    let hammertime = new Hammer(myElement, {})
    hammertime.get("pinch").set({
      enable: true,
    })
    var pos = { x: 0, y: 0, maxX: 0, maxY: 0 },
      last_scale = 1,
      transform = "",
      el = myElement

    hammertime.on("doubletap pan pinch panend pinchend wheel", (ev) => {
      if (this.scale == 1) {
        return
      }

      if (ev.type == "doubletap") {
        if (this.scale > 1) {
          this.compress()
        } else {
          this.expand()
        }
        return
      }

      if (this.scale != 1) {
        pos = this.getPosition(ev.deltaX, ev.deltaY, el)
      }

      //pinch
      if (ev.type == "pinch") {
        this.scale = Math.max(0.999, Math.min(last_scale * ev.scale, 5))
      }
      if (ev.type == "pinchend") {
        last_scale = this.scale
      }

      //panend
      if (ev.type == "panend") {
        this.lastPosX = pos.x < pos.maxX ? pos.x : pos.maxX
        this.lastPosY = pos.y < pos.maxY ? pos.y : pos.maxY
      }

      if (this.scale != 1) {
        transform = `translate(${pos.x}px, ${pos.y}px) scale(${this.scale}, ${this.scale})`
      }

      if (transform) {
        el.style.display = "none"
        el.style.webkitTransform = transform
        el.style.display = "block"
      }
    })
  }

  get isCircleIcon() {
    return this.zone && this.zone.zone_seats_icon == "circle"
  }

  drawSeats() {
    if (!this.initializedMap) {
      this.generateVenueMap().then(() => {
        this.drawSeats()
      })
      return
    }

    _.each(this.zone.seats, (seat, index) => {
      const group = this.svgContainer.group()
      group.viewbox(0, 0, this.seatLengthX, this.seatLengthY).attr({
        width: this.seatLengthX,
        height: this.seatLengthY,
        status: seat.status,
        representation: seat.representation,
        seatId: seat.id,
        seatIndex: index,
        class:
          seat.status == "available" && seat.representation == "standard"
            ? "available"
            : seat.status == "available" && seat.representation == "reduced_mobility"
            ? group.path(wheelChair.attr.d).attr(wheelChair.attr) && "reduced-mobility"
            : seat.status == "perimeter_block"
            ? "perimeter-block"
            : (seat.status == "ongoing" ||
                seat.status == "blocked" ||
                seat.status == "taken") &&
              seat.representation == "reduced_mobility"
            ? group.path(wheelChair.attr.d).attr(wheelChair.attr) && "not-available"
            : "not-available",
      })
      group
        .element("title")
        .words(`${this.zone.rows_name}: ${seat.row} - Butaca: ${seat.name}`)
      group.move(seat.coord_x + SEAT_LABEL_PADDING, seat.coord_y + SEAT_LABEL_PADDING)

      if (this.isCircleIcon) {
        group.circle(this.seatLengthX)
      } else {
        group.rect(this.seatLengthX, this.seatLengthY)
        group.attr({
          fill:
            seat.status == "available" && seat.representation == "standard"
              ? this.patternAvailable
              : seat.status == "available" && seat.representation == "reduced_mobility"
              ? group.path(wheelChair.attr.d).attr(wheelChair.attr) &&
                this.patternReducedMobility
              : seat.status == "perimeter_block"
              ? this.patternPerimeterBlock
              : (seat.status == "ongoing" ||
                  seat.status == "blocked" ||
                  seat.status == "taken") &&
                seat.representation == "reduced_mobility"
              ? group.path(wheelChair.attr.d).attr(wheelChair.attr) &&
                this.patternNotAvailable
              : this.patternNotAvailable,
        })
      }

      // Add label if required
      if (seat.row_label_position) {
        const labelOffset = LABEL_OFFSETS[seat.row_label_position]
        const labelText = this.svgContainer
          .text(`${seat.row}`)
          .font({
            size: 8.5,
            weight: "500",
            family: "Roboto,sans-serif",
          })
          .move(
            seat.coord_x + labelOffset.x + SEAT_LABEL_PADDING,
            seat.coord_y + labelOffset.y + SEAT_LABEL_PADDING
          )

        if (seat.row_label_position === "left") {
          labelText.font({ style: "text-anchor:end" })
        }
        labelText.addTo(this.svgContainer)
      }
      // Add extra atributes for confirmseatdialog
      group.attr("data-row", seat.row)

      this.initializePath(group)
      group.addTo(this.svgContainer)
    })

    this.svgContainer.viewbox(0, 0, this.getViewboxWidth(), this.getViewboxHeight())
    this.setColors()
  }

  isSelected(seatId: number) {
    return (
      _.findIndex(
        this.$store.state.shoppingCart.cart,
        (x: any) => x && x.seatId == seatId
      ) > -1
    )
  }

  initializePath(group: svgJs.G) {
    group.on("mousedown", (e: any) => {
      e.stopPropagation()
      const seatIndex = group.attr("seatIndex")
      const seat = this.zone.seats[seatIndex]
      if (this.isSelected(group.attr("seatId"))) {
        this.deselectGroup(group)
      } else {
        if (this.totalPurchasableSeatsCount == this.numTickets) {
          const translated = this.$gettext(
            "El máximo de localidades que se pueden comprar por pedido es de %{ n }"
          )
          const msg = this.$gettextInterpolate(translated, {
            n: this.totalPurchasableSeatsCount,
          })
          this.$store.dispatch("ui/error", msg)
        } else if (seat.has_notice) {
          if (group.attr("class") == "not-available") return
          if (group.attr("class") == "perimeter-block") return
          this.seatMessageGroup = group
          this.seatWithNotice = seat
        } else {
          this.selectGroup(group)
        }
      }
    })
  }

  setColors() {
    _.each(this.svgContainer.children(), (group) => {
      if (this.isSelected(group.attr("seatId")) && group.attr("class") != "selected") {
        this.selectGroup(group)
      } else if (
        !this.isSelected(group.attr("seatId")) &&
        group.attr("class") == "selected"
      ) {
        this.deselectGroup(group)
      }
    })
  }

  selectGroup(group: any) {
    const line = {
      sessionZoneId: this.zone.id,
      seatId: group.attr("seatId"),
      concessionId: null,
    }

    if (group.attr("class") == "not-available") return
    if (group.attr("class") == "perimeter-block") return
    if (group.attr("class") == "reduced-mobility") {
      if (!this.isCircleIcon) {
        group.attr("class", "selected")
        group.last().remove()
        group.last().attr({ fill: this.patternSelected })
        group.path(iconTick.attr.d).attr(iconTick.attr)
      } else {
        group.attr("class", "selected")
      }

      this.$store.dispatch("shoppingCart/update", ["addSeat", line])
      this.$emit("seatAdded", [line])
      return
    }

    if (!this.isCircleIcon) {
      group.last().attr({ fill: this.patternSelected })
      group.path(iconTick.attr.d).attr(iconTick.attr)
      group.attr("class", "selected")
    } else {
      group.attr("class", "selected")
    }

    this.$store.dispatch("shoppingCart/update", ["addSeat", line])

    this.$emit("seatAdded", [line])
  }

  deselectGroup(group: any) {
    if (group.attr("representation") == "reduced_mobility") {
      if (!this.isCircleIcon) {
        group.attr("class", "reduced-mobility")
        group.last().remove()
        group.last().attr({ fill: this.patternReducedMobility })
        group.path(wheelChair.attr.d).attr(wheelChair.attr)
      } else {
        group.attr("class", "reduced-mobility")
      }

      const cartLine = this.$store.getters["shoppingCart/getCartLineBySeatId"](
        group.attr("seatId")
      )

      if (cartLine) {
        this.$emit("seatRemoved", cartLine)
      }

      this.$store.dispatch("shoppingCart/update", ["removeSeat", group.attr("seatId")])
      return
    }

    if (!this.isCircleIcon) {
      group.attr("class", "available")
      group.last().remove()
      group.last().attr({ fill: this.patternAvailable })
    } else {
      group.attr("class", "available")
    }

    const cartLine = this.$store.getters["shoppingCart/getCartLineBySeatId"](
      group.attr("seatId")
    )

    if (cartLine) {
      this.$emit("seatRemoved", cartLine)
    }

    this.$store.dispatch("shoppingCart/update", ["removeSeat", group.attr("seatId")])
  }

  @Watch("$store.state.shoppingCart.cart", { deep: true })
  onCartChange() {
    this.setColors()
  }

  @Watch("zone", { deep: true })
  onZoneChange() {
    this.svgContainer.remove()
    this.initializedMap = false
    this.drawSeats()
  }
}
