
































































import _ from "lodash"
import "reflect-metadata"
import { Component, Watch } from "vue-property-decorator"
import { mapState } from "vuex"
import Vue from "vue"
import Cart from "@/components/Cart.vue"
import CartIcon from "@/components/CartIcon.vue"
import CalendarChangeSession from "@/components/calendar/CalendarChangeSession.vue"
import ConcessionsList from "@/components/ConcessionsList.vue"
import EventDetail from "@/components/EventDetail.vue"
import ZoneMap from "@/components/ZoneMap.vue"
import TokenTimer from "@/components/TokenTimer.vue"
import TokenExpiredDialog from "@/components/TokenExpiredDialog.vue"

@Component({
  components: {
    Cart,
    CartIcon,
    CalendarChangeSession,
    ConcessionsList,
    EventDetail,
    ZoneMap,
    TokenTimer,
    TokenExpiredDialog,
  },
  computed: {
    ...mapState({
      cart: (state: any) => state.shoppingCart.cart,
    }),
  },
})
export default class Zone extends Vue {
  eventSimple: Dictionary<any> | null = null
  session: {
    id: null
    lookup_ref: null
    start_date: string
    start_time: string
    stage_layout: Array<Dictionary<any>> | null
    session_zones: Array<Dictionary<any>>
    event: Array<Dictionary<any>> | {}
  } | null = null
  zone?: Dictionary<any> | null = null
  ticketsDropdownOpen: boolean = false
  tokenExpired = false

  get isLoading() {
    return !this.session
  }

  get sessionZones(): Array<Dictionary<any>> {
    if (!this.session) return []
    return this.session.session_zones
  }

  get purchasableZones(): Array<Dictionary<any>> {
    return _.filter(
      this.sessionZones,
      (sessionzone) =>
        sessionzone.purchasable_seats_count > 0 && sessionzone.total_price !== null
    )
  }

  get isNumbered() {
    return this.zone != null && this.zone.seat_type == "numbered"
  }

  get isUnnumbered() {
    return this.zone != null && this.zone.seat_type == "unnumbered"
  }

  mounted() {
    this.$store.commit("shoppingCart/toggle", true)
    this.getEventSimple()
    this.getSession()
    this.getZone()
  }

  getEventSimple() {
    this.$api.getEventSimple(this.$route.params.event_lookup_ref).then(
      (response: any) => {
        this.eventSimple = response
      },
      () => {}
    )
  }

  getSession() {
    this.$api
      .getSession(this.$route.params.session_lookup_ref)
      .then((response: any) => {
        this.session = response
      })
  }

  getZone() {
    this.$api.getZone(this.$route.params.zone_id).then((response: any) => {
      this.zone = response

      this.$store.dispatch("gtm2404/checkoutZone", { zone: this.zone })
    })
  }

  seatAdded(lines: any) {
    this.$store.dispatch("gtm2404/checkoutZoneAddToCart", { zone: this.zone, lines })
  }

  seatRemoved(price: any) {
    this.$store.dispatch("gtm2404/removeFromCart", {
      zone: this.zone,
      price: price,
    })
  }

  seatRemovedNumbered(cartLine: any) {
    let price = this.zone?.ticket_price

    if (cartLine && cartLine.concessionId) {
      // Busca el precio de esa línea
      // si tiene concesión, sino es el precio
      // normal de la zona
      const concessions = this.zone?.concessions.filter(
        (c: any) => c.id == cartLine.concessionId
      )
      const concession = concessions ? concessions[0] : null

      if (concession) {
        price = concession.ticket_price
      }
    }

    this.$store.dispatch("gtm2404/removeFromCart", {
      zone: this.zone,
      price: price,
    })
  }

  @Watch("$route")
  onSessionChange() {
    if (
      this.session &&
      this.$route.params.session_lookup_ref != this.session.lookup_ref
    ) {
      this.getSession()
    }

    if (this.zone && this.$route.params.zone_id != this.zone.id) {
      this.getZone()
    }
  }

  get ffTokenTimerEnabled() {
    return this.$env && this.$env.ff_token_timer === true
  }
}
