





























import "reflect-metadata"
import { Component, Prop, Watch } from "vue-property-decorator"
import _ from "lodash"
import Vue from "vue"
import filters from "@/filters"

@Component({
  filters: filters,
})
export default class ConcessionCard extends Vue {
  numTickets: number | null = 0
  @Prop({ default: {} }) zone!: {
    total_price?: number
    purchasable_seats_count?: number
    concessions?: object
  }
  @Prop({ default: {} }) concession!: {
    name?: string
    total_price?: number
    id?: number
  }
  @Prop({ default: false }) hasFees!: boolean

  get sellableSeatsItems(): Array<number> {
    if (!this.zone.purchasable_seats_count) return []
    return _.range(0, this.zone.purchasable_seats_count + 1)
  }

  selectTickets() {
    this.$emit("update:data", {
      concessionId: this.concession.id,
      numTickets: this.numTickets,
      totalPrice: this.concession.total_price,
    })
  }

  @Watch("$store.state.shoppingCart.cart.length")
  onCartLengthChange() {
    this.numTickets = null
  }
}
